<template>
    <header class="relative">
        <div class="container laptop-header  align-items-center">
            <nuxt-link class="logo" to="/">
                <NuxtImg src="/img/tray.svg" width="35px" class="mr-2" />
                <span>OnLine.menu</span>
            </nuxt-link>

            <div class="links">
                <nuxt-link v-for="item in data" :to="item.to">
                    {{ item.name }}
                </nuxt-link>
            </div>

            <div class="header-buttons">
                <nuxt-link
                    class="login-btn"
                    :class="user.isAuthenticated ? 'px-3' : 'px-5'"
                    :to="user.isAuthenticated ? '/manager' : '/login'"
                >
                    {{ user.isAuthenticated ? 'Dashboard' : 'Login' }}
                </nuxt-link>
            </div>
        </div>

        <div class="mobile-header container justify-between">
            <nuxt-link class="logo" to="/">
                <NuxtImg src="/img/tray.svg" width="35px" class="mr-2" />
                <span>OnLine.menu</span>
            </nuxt-link>

            <Icon
                color="#f1f1f1"
                @click="showMobileMenu = !showMobileMenu"
                :size="showMobileMenu ? '36px' : '26px'"
                :class="showMobileMenu ? 'open' : 'close'"
                :name="showMobileMenu ? 'typcn:times-outline' : 'typcn:th-menu-outline'"
            />

            <nav v-if="showMobileMenu" class="mobile-menu absolute">
                <div class="container d-flex flex-column items-center">
                    <nuxt-link v-for="item in data" :to="item.to">
                        {{ item.name }}
                    </nuxt-link>

                    <div class="py-2">
                        <nuxt-link
                            class="login-btn"
                            :class="user.isAuthenticated ? 'px-3' : 'px-5'"
                            :to="user.isAuthenticated ? '/manager' : '/login'"
                        >
                            {{ user.isAuthenticated ? 'Dashboard' : 'Login' }}
                        </nuxt-link>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router'

const user = useUserStore();
const showMobileMenu = ref(false);
const data = [
    {name: 'About Us', to: '/#about'},
    {name: 'Advantages', to: '/#advantages'},
    {name: 'Conditions', to: '/#conditions'},
    {name: 'FAQ', to: '/#faq'},
];

const router = useRouter();
</script>

<style lang="scss" scoped>
@import '~/assets/css/rfs.scss';
@import '~/assets/css/_include-media.scss';

header {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--dl-dark-main);
    
    .logo {
        display: flex;
        font-weight: 600;
        align-items: center;
        flex-direction: row;
        font-family: "Raleway";
        @include font-size(1.3rem);
    }

    .links {
        flex-grow: 1;
        display: flex;
        padding: 0 30px;
        align-items: center;

        a {
            padding: 0 10px;
            display: inline-flex;
            @include font-size(1.15rem);
        }
    }

    .login-btn {
        @apply inline-flex items-center;
        @apply flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 py-1.5 shadow-sm;
        @apply focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 hover:bg-primary-600;
        @apply text-white dark:text-gray-900 bg-primary-500 disabled:bg-primary-500 dark:bg-primary-400;
        @apply dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2;
        @apply focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400;
    }

    .mobile-header {
        nav {
            left: 0;
            right: 0;
            top: 100%;
            padding-bottom: 10px;
            background-color: var(--dl-dark-main);

            .container a:first-child {
                border-top: 1px solid darkgray;
            }

            a {
                width: 50%;
                padding: 7px 0;
                text-align: center;
                @include font-size(1.3rem);
            }
        }

        .icon {
            &.open {
                transform: translate(5px, -5px);
            }
        }
    }

    @include media(">phone") {
        .laptop-header {
            display: flex;
        }

        .mobile-header {
            display: none;
        }
    }

    @include media("<=phone") {
        .laptop-header {
            display: none;
        }

        .mobile-header {
            display: flex;
        }
    }

    @include media("<desktop") {
        .logo span {
            display: none;
        }
    }
}
</style>