<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex justify-between footer_nav">
                    <NuxtImg src="/img/tray.svg" width="35px" class="logo mr-5" />

                    <nuxt-link class="mobile-logo" to="/">
                        <NuxtImg src="/img/tray.svg" width="35px" class="mr-2" />
                        <span>OnLine.menu</span>
                    </nuxt-link>

                    <div class="links">
                        <nuxt-link v-for="item in data" :to="item.to">
                            {{ item.name }}
                        </nuxt-link>
                    </div>

                    <a class="email" href="mailto:ildar7sins@gmail.com">ildar7sins@gmail.com</a>
                </div>

                <div class="col-12">
                    <UDivider
                            class="my-3 divider"
                            label="Important information"
                            :ui="{ label: 'text-primary-500 dark:text-primary' }"
                    />
                </div>

                <div class="col-12 d-flex justify-between footer-info">
                    <div class="row w-100">
                        <div class="col-12 col-md-6 d-flex flex-column">
                            <span>
                                By using the service you agree to the terms of use.
                            </span>
                            <nuxt-link class="privacy-policy" to="/privacy-policy">
                                Terms of use "OnLine.menu"
                            </nuxt-link>
                        </div>

                        <div class="col-12 col-md-6 d-flex flex-column privacy-policy">
                            © 2023 — {{ new Date().getFullYear() }} -«OnLine.menu»
                            <nuxt-link class="privacy-policy" to="/privacy-policy">Privacy Policy</nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">

const data = reactive([
    {name: 'About Us', to: '/#about'},
    {name: 'Why?', to: '/#why'},
    {name: 'Price', to: '/#price'},
    {name: 'FAQ', to: '/#faq'},
]);
</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';
@import '~/assets/css/_include-media.scss';

footer {
    padding: 35px 0;
    background-color: var(--dl-dark-main);

    .mobile-logo {
        display: none;
    }

    .links {
        flex-grow: 1;
        display: flex;
        align-items: center;

        a {
            padding: 0 10px;
            display: inline-flex;
            @include font-size(1.15rem);
        }
    }

    .privacy-policy {
    }
}


@include media(">phone") {
    .footer-info .privacy-policy {
        align-items: flex-end;
    }
}

@include media("<=phone") {
    .logo {
        display: none;
    }

    .mobile-logo {
        margin: 0 0 15px;
        font-weight: 600;
        align-items: center;
        flex-direction: row;
        font-family: "Raleway";
        justify-content: center;
        display: flex !important;
        @include font-size(1.3rem);


        img {
            width: 50px;
        }
    }

    .footer_nav {
        align-items: center;
        flex-direction: column;

        .links {
            display: flex;
            align-items: center;
            flex-direction: column;

            a {
                margin-bottom: 10px;
                @include font-size(1.75rem);
            }
        }

        .email {
            @include font-size(1.75rem);
        }
    }

    .footer-info {
        .col-12 {
            text-align: center;
            margin-bottom: 20px;
        }
    }
}
</style>

<style lang="scss">
@import '~/assets/css/rfs.scss';

footer {
    .divider {
        margin: 25px 0 !important;

        span {
            @include font-size(1.2rem);
        }
    }
}
</style>