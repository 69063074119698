<template>
    <div class="default-layout">
        <Header />
        <slot />
        <Footer />
    </div>
</template>

<script setup lang="ts">
import Header from "~/components/landing/Header.vue";
import Footer from "~/components/landing/Footer.vue";

useHead({
    htmlAttrs: {
        // class: 'dark'
    }
})

</script>

<style>
.default-layout {
    --dl-dark-main: #202123;
}
</style>
